/* eslint-disable */ 
import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { GET_SECTION_CONTENT_DATA, 
          GET_PRICING_PLAN_DATA, 
          GET_MAIN_CATEGORIES, 
          GET_SUB_CATEGORIES,
          GET_CONTACT_INFO,
          GET_LOCATION_INFO,
          GET_TEAM_DATA } from "./queries";
import axios from "axios";

export class OcContentService {
  static defaultClient = new ApolloClient({
    uri: process.env.VUE_APP_OC_GQL_API,
    cache: new InMemoryCache(),
  });

  static response;
  
  static async fetchPageData (pageName, contentType) {
    let promiseData = null;
    
    if (pageName && contentType) {
      var target_query = GET_SECTION_CONTENT_DATA;
  
      switch (contentType) {
        case "KrickersSectionContent":
          target_query = GET_SECTION_CONTENT_DATA;
          break;
        case "KrickersPricingPlanItem":
          target_query = GET_PRICING_PLAN_DATA;
          break;
        case "KrickersContactInformationItem":
          target_query = GET_CONTACT_INFO;
          break;
        case "KrickersLocationItem":
          target_query = GET_LOCATION_INFO;
          break;
        case "KrickersTeamMember":
          target_query = GET_TEAM_DATA;
          break;
      }

      promiseData = this.defaultClient
      .query({
        query: target_query,
        variables: {
          pagename: pageName,
        },
      }).then(res => {return res.data.page[0].flow.widgets.filter((w) => w.contentType == contentType)})
    }

    return promiseData
  }

  static async fetchMainCategories() {
    const promiseData = this.defaultClient
    .query({
      query: GET_MAIN_CATEGORIES,
    }).then(res => {return res.data.krickersMainCategory})

    return promiseData
  }

  static async fetchSubCategories() {
    const promiseData = this.defaultClient
    .query({
      query: GET_SUB_CATEGORIES,
    }).then(res => {return res.data.krickersSubcategory})

    return promiseData
  }

  static formatImage(imagePath) {
    return process.env.VUE_APP_OC_MEDIA_API + imagePath;
  }
}