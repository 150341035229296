import axios from "axios";
export class UploadcareService {
    static options = {
        headers: {
            "Authorization": "Uploadcare.Simple " + process.env.VUE_APP_UPLOADCARE_PUB_KEY + ":" + process.env.VUE_APP_UPLOADCARE_SEC_KEY,
            "Content-Type": "application/json"
        }
    }

    static async uploadProfileImage(filename, file) {
        let data = new FormData();
        data.append("UPLOADCARE_PUB_KEY", process.env.VUE_APP_UPLOADCARE_PUB_KEY);
        data.append("UPLOADCARE_STORE", "auto");
        data.append(filename, file);

        let resp = await axios.post(process.env.VUE_APP_UPLOADCARE_UPLOAD_API + "base/", data);
        //let nopresp = this.storeInNop(username, resp.file);
        return resp;
    }

    static async uploadHeroImage(filename, file) {
        let data = new FormData();
        data.append("UPLOADCARE_PUB_KEY", process.env.VUE_APP_UPLOADCARE_PUB_KEY);
        data.append("UPLOADCARE_STORE", "auto");
        data.append(filename, file);

        let resp = await axios.post(process.env.VUE_APP_UPLOADCARE_UPLOAD_API + "base/", data);
        //let nopresp = this.storeInNop(username, resp.file);
        return resp;
    }

    static async uploadImage(filename, file) {
        let data = new FormData();
        data.append("UPLOADCARE_PUB_KEY", process.env.VUE_APP_UPLOADCARE_PUB_KEY);
        data.append("UPLOADCARE_STORE", "1");
        data.append(filename, file);

        let resp = await axios.post(process.env.VUE_APP_UPLOADCARE_UPLOAD_API + "base/", data);
        //let nopresp = this.storeInNop(username, resp.file);
        return resp;
    }

    static async getImageData(uuid) {
        let api = process.env.VUE_APP_UPLOADCARE_REST_API + "files/" + uuid + "/";
        let resp = await axios.get(api, this.options);
        return resp;
    }

    static async deleteImage(uuid) {
        let api = process.env.VUE_APP_UPLOADCARE_REST_API + "files/" + uuid + "/storage/";
        let resp = await axios.delete(api, this.options);
        //let nopresp = await this.deleteFromNop(username, uuid);
        return resp;
    }

    static buildImageUrl(uuid, transformation = null) {
        let imageUrl = process.env.VUE_APP_UPLOADCARE_IMAGE_BASE + uuid + "/";
        if (transformation) {
            imageUrl += transformation;
        }
        return imageUrl;
    }

    static async saveCroppedImage(croppedImageUrl) {
        /*
        curl -X POST \\
        -H "Authorization: Uploadcare.Simple demopublickey:demoprivatekey" \\ 
        -d "source=https://ucarecdn.com/3a75edda-e631-4b62-96fd-06fbe3ce534a/-/crop/168x137/30,11/-/preview/" \\ 
        -d "store=true" \\     
        "https://api.uploadcare.com/files/"
        */
        let data = new FormData();
        data.append("store", "true");
        data.append("source", croppedImageUrl);

        let resp = await axios.post(process.env.VUE_APP_UPLOADCARE_REST_API + "files/", data, this.options);
        //let nopresp = this.storeInNop(username, resp.file);
        return resp;
    }
}