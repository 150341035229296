import axios from "axios";
import { CookieService } from "./cookieService";
export class NopService {
    static defaultHeaders = {
        headers: {
            "Authorization": "Basic " + process.env.VUE_APP_NOP_AUTH,
            "Content-Type": "application/json"
        }
    }

    static async LogIn(jsonParams, rememberMe) {
        let api = process.env.VUE_APP_NOP_BASE + "login";
        let resp = await axios.post(api, jsonParams, this.defaultHeaders);
        CookieService.createCookie(resp.data.Data.Id);
        if (rememberMe) {
            CookieService.createCredsCookie(jsonParams.Email, jsonParams.Password);
        }
        else {
            CookieService.removeCredsCookie();
        }
        return resp;
    }

    static LogOut() {
        CookieService.removeLoginCookie();
    }

    static async CreateCustomer(jsonParams) {
        let api = process.env.VUE_APP_NOP_BASE + "createcustomer";
        let resp = await axios.post(api, jsonParams, this.defaultHeaders);

        return resp
    }

    static async UpdateCustomer(jsonParams) {
        let api = process.env.VUE_APP_NOP_BASE + "updatecustomer";
        let resp = await axios.post(api, jsonParams, this.defaultHeaders);

        return resp.data
    }

    static async GetAllCustomers() {
        let api = process.env.VUE_APP_NOP_BASE + "getallcustomers";
        
        let resp = await axios.post(api, this.defaultHeaders)
        return resp
    }

    static async GetCustomerDetailsByEmail(email) {
        let api = process.env.VUE_APP_NOP_BASE + "getcustomer";
        let params = {
            params: {
                email: email
            }
        }
        let resp = await axios.get(api, params, this.defaultHeaders)
        return resp.data.Data
    }

    static async GetCustomerDetailsByUsername(username) {
        let api = process.env.VUE_APP_NOP_BASE + "getcustomerbyusername";
        let params = {
            params: {
                username: username
            }
        }
        let resp = await axios.get(api, params, this.defaultHeaders)
        return resp.data.Data
    }

    static async GetCustomerDetailsById(id) {
        let api = process.env.VUE_APP_NOP_BASE + "getcustomerbyid";
        let params = {
            params: {
                id: id
            }
        }
        let resp = await axios.get(api, params, this.defaultHeaders)
        return resp.data.Data
    }

    static async requestPasswordReset(email) {
        let api = process.env.VUE_APP_NOP_BASE + "PasswordRecoverySend?email=" + email;
        let resp = await axios.post(api, this.defaultHeaders)

        return resp.data
    }

    static async confirmPasswordTokens(token, guid) {
        let api = process.env.VUE_APP_NOP_BASE + "PasswordRecoveryConfirm";
        let params = {
            params: {
                token: token,
                guid: guid,
                email: "",
            }
        }
        let resp = await axios.post(api, {}, params, this.defaultHeaders)

        return resp.data
    }

    static async submitNewPassword(token, guid, newPassword) {
        let api = process.env.VUE_APP_NOP_BASE + "PasswordRecoveryConfirmPost";
        let params = {
            token: token,
            guid: guid,
            email: "",
            NewPassword: newPassword,
            ConfirmNewPassword: newPassword
        }
        let resp = await axios.post(api, params, this.defaultHeaders)

        return resp.data
    }

    static async validateGuid(guid) {
        let api = process.env.VUE_APP_NOP_BASE + "ValidateGuid?guid=" + guid;
        // let params = {
        //     guid: guid
        // }
        // const params = new URLSearchParams();
        // params.append("guid", guid);
        let resp = await axios.post(api, this.defaultHeaders);

        return resp;
    }

    static async activateAccount(token, guid) {
        let api = process.env.VUE_APP_NOP_BASE + "Activation";
        let params = {
            token: token,
            guid: guid,
            email: "",
        }
        let resp = await axios.post(api, params, this.defaultHeaders);

        return resp;
    }

    static async validateRecaptcha(gtoken) {
        //'g-recaptcha-response', gtoken
        let api = process.env.VUE_APP_NOP_BASE + "ValidateRecaptcha";
        let params = {
            params: {
                clientResponse: gtoken
            }
        }
        let resp = await axios.post(api, {}, params, this.defaultHeaders)
        return resp
      }
}