<template>
  <header>
    <div class="relative bg-white relative z-50 shadow">
      <div
        class="
          flex
          justify-between
          items-center
          max-w-7xl
          mx-auto
          px-4
          py-4
          sm:px-6
          md:justify-start md:space-x-10
          lg:px-8
        "
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link to="/">
            <span class="text-logo from-indigo-700 to-indigo-500">Krickers</span>
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden ml-auto">
          <button
            type="button"
            class="
              bg-white
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-gray-400
              hover:text-gray-500 hover:bg-gray-100
              focus:outline-none
              focus:ring-2
              focus:ring-inset
              focus:ring-indigo-500
            "
            aria-expanded="false"
            v-on:click="showMenu = true"
          >
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: outline/menu -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <button
          v-on:click="loggedin = true"
          id="signInButton"
          style="height: 0px; width: 0px"
        ></button>
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <router-link
            v-if="!isLoggedIn"
            id="signin"
            to="/login"
            class="
              whitespace-nowrap
              text-base
              font-medium
              text-gray-500
              hover:text-gray-900
            "
          >
            Sign In 
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block ml-1 -mt-1 opacity-60" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
            </svg>
          </router-link>
          <router-link
            v-if="!isLoggedIn"
            id="signout"
            to="/register"
            class="
              ml-8
              whitespace-nowrap
              inline-flex
              items-center
              justify-center
              bg-gradient-to-r
              from-blue-500
              to-blue-700
              bg-origin-border
              px-4
              py-2
              border border-transparent
              rounded-md
              shadow-sm
              text-base
              font-medium
              text-white
              hover:from-blue-700 hover:to-blue-500
            "
          >
            Sign Up
          </router-link>
          <router-link
            id="signout"
            to="/search"
            class="
              ml-4
              whitespace-nowrap
              inline-flex
              items-center
              justify-center
              bg-gradient-to-r
              from-indigo-600
              to-indigo-700
              bg-origin-border
              px-4
              py-2
              border border-transparent
              rounded-md
              shadow-sm
              text-base
              font-medium
              text-white
              hover:from-indigo-700 hover:to-indigo-800
            "
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 - inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="#fff"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg> Search For Talent
          </router-link>

          <Menu as="div" class="ml-5 relative test" v-if="isLoggedIn">
            <div>
              <MenuButton
                class="
                  max-w-xs
                  bg-white
                  flex
                  items-center
                  text-sm
                  rounded-full
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" :src="imageUrl" alt="" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="
                  origin-top-right
                  absolute
                  right-0
                  mt-2
                  w-48
                  rounded-md
                  shadow-lg
                  py-1
                  bg-white
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                "
              >
                <MenuItem
                  v-for="item in userNavigation"
                  :key="item.name"
                  v-slot="{ active }"
                >
                  <router-link
                    :to="item.href"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                  >
                    {{ item.name }}
                  </router-link>
                </MenuItem>
                <router-link
                  to="/login"
                  class="block px-4 py-2 text-sm text-gray-700"
                  v-on:click="logout"
                >
                  Sign out
                </router-link>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>

      <!--
        Mobile menu, show/hide based on mobile menu state.

        Entering: "duration-200 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      -->
      <div
        class="
          absolute
          z-30
          top-0
          inset-x-0
          p-2
          transition
          transform
          origin-top-right
          md:hidden
        "
        :class="[showMenu ? 'block' : 'hidden']"
      >
        <div
          class="
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            bg-white
            divide-y-2 divide-gray-50
          "
        >
          <div class="pt-2 pb-6 px-4">
            <div class="flex items-center justify-between">
              <div>
                <router-link to="/">
                  <span class="text-logo text-transparent bg-clip-text bg-gradient-to-r from-indigo-700 to-indigo-500">Krickers</span>
                </router-link>
              </div>
              <div class="-mr-2">
                <button
                  type="button"
                  class="
                    bg-white
                    rounded-md
                    p-2
                    inline-flex
                    items-center
                    justify-center
                    text-gray-400
                    hover:text-gray-500 hover:bg-gray-100
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-indigo-500
                  "
                  v-on:click="showMenu = false"
                >
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: outline/x -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-4">
              <div>
                <Menu as="div" class="relative -mt-2 mb-2" v-if="isLoggedIn">
                      <MenuItem
                        v-for="item in userNavigation"
                        :key="item.name"
                      >
                        <router-link
                          :to="item.href"
                          class=" block py-2 text-sm text-gray-700 border-b"
                  v-on:click="showMenu = false"
                        >
                          {{ item.name }}
                        </router-link>
                      </MenuItem>
                      <router-link
                        to="/login"
                        class="block py-2 text-sm text-gray-700 border-b"
                        v-on:click="logout"
                      >
                        Sign out
                      </router-link>
                </Menu>
              </div>
              <div class="grid grid-cols-1 gap-4 border-b pb-5 mb-5">
                <router-link
                  id="signout"
                  to="/search"
                  class="
                    whitespace-nowrap
                    inline-flex
                    items-center
                    justify-center
                    bg-gradient-to-r
                    from-indigo-600
                    to-indigo-700
                    bg-origin-border
                    px-4
                    py-2
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-base
                    font-medium
                    text-white
                    hover:from-indigo-700 hover:to-indigo-800
                  "
                  v-on:click="showMenu = false"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 - inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="#fff"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg> Search For Talent
                </router-link>
                <router-link
                  v-if="!isLoggedIn"
                  id="signout"
                  to="/register"
                  class="
                    whitespace-nowrap
                    inline-flex
                    items-center
                    justify-center
                    bg-gradient-to-r
                    from-blue-500
                    to-blue-700
                    bg-origin-border
                    px-4
                    py-2
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-base
                    font-medium
                    text-white
                    hover:from-blue-700 hover:to-blue-500
                  "
                  v-on:click="showMenu = false"
                >
                  Sign Up
                </router-link>
                <router-link
                  v-if="!isLoggedIn"
                  id="signin"
                  to="/login"
                  class="
                    whitespace-nowrap
                    inline-flex
                    items-center
                    justify-center
                    bg-origin-border
                    px-4
                    py-2
                    border
                    bg-gray-800
                    border-transparent
                    text-white
                    rounded-md
                    shadow-sm
                    text-base
                    font-medium
                    text-white
                    hover:from-blue-700 hover:to-blue-500
                  "
                  v-on:click="showMenu = false"
                >
                  Sign In 
                </router-link>
              </div>

              <nav class="grid grid-cols-1 gap-2">
                <a
                  href="/about"
                  class="
                    px-2
                    py-2
                    text-base
                    font-medium
                    text-gray-900
                    hover:text-indigo-600
                  "
                  v-on:click="showMenu = false"
                  >About</a
                >
                <a
                  href="/contact"
                  class="
                    px-2
                    py-2
                    text-base
                    font-medium
                    text-gray-900
                    hover:text-indigo-600
                  "
                  v-on:click="showMenu = false"
                  >Contact Us</a
                >
                <a
                  href="/acessibility"
                  class="
                    px-2
                    py-2
                    text-base
                    font-medium
                    text-gray-900
                    hover:text-indigo-600
                  "
                  v-on:click="showMenu = false"
                  >Accessibility</a
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import NavItem from "./NavItem.vue";
import Button from "./Button.vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import { CookieService } from "../services/cookieService";
import { NopService } from "../services/nopService";
import { UploadcareService } from "../services/uploadcareService";

// const user = {
//   name: 'Tom Cook',
//   email: 'tom@example.com',
//   imageUrl:
//     'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
// }
const userNavigation = [
  { name: "Your Profile", href: "/Profile/" },
  { name: "Settings", href: "/Dashboard" },
];

const navItems = [
  //{ path: "/", label: "Home" },
  //{ path: "/Contact-us", label: "Contact Us" },
];

const navItemsSignup = [{ path: "/register", label: "Sign Up" }];

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Button,
    MenuIcon,
    XIcon,
    NavItem,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  setup() {
    return { navItems, navItemsSignup, userNavigation };
  },
  data() {
    return {
      isLoggedIn: false,
      showMenu: false,
      imageUrl:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    };
  },
  async created() {
    let id = CookieService.getLoginCookie();
    if (id) {
      this.isLoggedIn = true;
      let userData = await NopService.GetCustomerDetailsById(id);
      let imageUrl = UploadcareService.buildImageUrl(
        userData.ProfileImageUUID,
        "-/resize/50/"
      );
      this.imageUrl = imageUrl;
      userNavigation[0].href += userData.Username;
    } else {
      this.logout();
    }
  },
  mounted() {
    this.emitter.on("logged-in", (isLoggedIn) => {
      if (isLoggedIn) {
        this.isLoggedIn = isLoggedIn;
      } else {
        this.logout();
      }
    });
    this.emitter.on("user-changed", (userData) => {
      let imageUrl = UploadcareService.buildImageUrl(
        userData.ProfileImageUUID,
        "-/resize/50/"
      );
      this.imageUrl = imageUrl;
      userNavigation[0].href = "/Profile/" + userData.Username;
    });
  },
  methods: {
    logout() {
      this.showMenu = false;
      CookieService.removeLoginCookie();
      this.isLoggedIn = false;
      sessionStorage.setItem("login", "false");
    },
  },
};
</script>
