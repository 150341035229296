/* eslint-disable */ 
import { gql } from '@apollo/client/core'

export const GET_SECTION_CONTENT_DATA = gql`
query GetSectionContentData ($pagename: String!) {
  page(where: {displayText: $pagename}) {
    flow {
      widgets {
        ... on KrickersSectionContent {
          bottomText
          contentType
          linkText
          topText
          middleText
          image {
            paths(first: 10)
          }
        }
      }
    }
  }
}
`

export const GET_PRICING_PLAN_DATA = gql`
query GetPricingPlanData ($pagename: String!) {
  page(where: {displayText: $pagename}) {
    flow {
      widgets {
        ... on KrickersPricingPlanItem {
          contentType
          planName
          features
          price
          isMostPopular
        }
      }
    }
  }
}
`

export const GET_CONTACT_INFO = gql`
query GetContactInfo ($pagename: String!) {
  page(where: {displayText: $pagename}) {
    flow {
      widgets {
        ... on KrickersContactInformationItem {
          email
          faxNumber
          phoneNumber
          tollFreeNumber
          contentType
        }
      }
    }
  }
}
`

export const GET_LOCATION_INFO = gql`
query GetContactInfo ($pagename: String!) {
  page(where: {displayText: $pagename}) {
    flow {
      widgets {
        ... on KrickersLocationItem {
          address
          city
          locationName
          postalCode
          province
          contentType
        }
      }
    }
  }
}
`

export const GET_TEAM_DATA = gql`
query GetSectionContentData ($pagename: String!) {
  page(where: {displayText: $pagename}) {
    flow {
      widgets {
        ... on KrickersTeamMember {
          contentType
          image {
            paths(first: 10)
          }
          name
          role
          team
        }
      }
    }
  }
}
`

export const GET_MAIN_CATEGORIES = gql`
query GetMainCategories {
  krickersMainCategory {
    category
    contentType
    disabled
    contentItemId
    contentType
  }
}
`

export const GET_SUB_CATEGORIES = gql`
query GetSubCategories {
  krickersSubcategory {
    category
    disabled
    contentType
    contentItemId
    parentCategory {
      contentItems {
        ... on KrickersMainCategory {
          category
          disabled
          contentType
          contentItemId
        }
      }
    }
  }
}
`