import * as CryptoJS from 'crypto-js'

export class CookieService {
    static createCookie(value) {
        //encrypt value and save that instead of raw text
        var message = CryptoJS.AES.encrypt(value + "", process.env.VUE_APP_ID_SALT).toString();
        let exp = new Date().addHours(1)
        //var exp = new Date(); //new Date().addHours(1)
        //exp.setSeconds(exp.getSeconds() + 10); //COMMENT THIS OUT
        let c_value = escape(message) + "; expires=" + exp.toUTCString() + "; path=/";
        document.cookie = "krickers_loggedin=" + c_value;
    }

    static getLoginCookie() {
        try {
            let encryptedValue = document.cookie.split('; ').find(row => row.startsWith('krickers_loggedin=')).split('=')[1].replace("%3D", "=");
            let code = CryptoJS.AES.decrypt(encryptedValue, process.env.VUE_APP_ID_SALT);
            let decryptedMessage = code.toString(CryptoJS.enc.Utf8);
            return decryptedMessage;
        } catch (error) {
            return "";
        }
    }

    static removeLoginCookie() {
        document.cookie = "krickers_loggedin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    static createCredsCookie(email, password) {
        //1. encrypt email and password
        let encEmail = CryptoJS.AES.encrypt(email, process.env.VUE_APP_USERNAME_SALT).toString();
        let encPassword = CryptoJS.AES.encrypt(password, process.env.VUE_APP_PASSWORD_SALT).toString();
        
        //2. store username in cookie
        let exp = new Date(2100, 0, 1, 12); //"never" expires
        let c_value = escape(encEmail) + "; expires=" + exp.toUTCString() + "; path=/";
        document.cookie = "krickers_rememberUser=" + c_value;

        //3. store password in cookie
        exp = new Date(2100, 0, 1, 12); //"never" expires
        c_value = escape(encPassword) + "; expires=" + exp.toUTCString() + "; path=/";
        document.cookie = "krickers_rememberPass=" + c_value;
    }

    static getRememberMeCookie() {
        try {
            let encUser = document.cookie.split('; ').find(row => row.startsWith('krickers_rememberUser=')).split('=')[1].replace("%3D", "=");
            let encPass = document.cookie.split('; ').find(row => row.startsWith('krickers_rememberPass=')).split('=')[1].replace("%3D", "=");
            let username = CryptoJS.AES.decrypt(encUser, process.env.VUE_APP_USERNAME_SALT);
            let password = CryptoJS.AES.decrypt(encPass, process.env.VUE_APP_PASSWORD_SALT);
            username = username.toString(CryptoJS.enc.Utf8);
            password = password.toString(CryptoJS.enc.Utf8);

            return {Email: username, Password: password};
            // return document.cookie
            // .split('; ')
            // .find(row => row.startsWith('krickers_rememberme='))
            // .split('=')[1];
        } catch (error) {
            return "";
        }
    }

    static removeCredsCookie() {
        document.cookie = "krickers_rememberUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "krickers_rememberPass=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
}

Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}