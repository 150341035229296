<template>
  <div>
    <main class="relative">
      <div
        class="
          absolute
          inset-y-0
          left-0
          top-0
          w-1/4
          h-screen-50
          bg-gray-50
          rounded-r-3xl
          h-3/4
        "
      ></div>

      <svg
        class="absolute bottom-8 left-1/6 -ml-3"
        width="604"
        height="392"
        fill="none"
        viewBox="0 0 404 392"
      >
        <defs>
          <pattern
            id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            ></rect>
          </pattern>
        </defs>
        <rect
          width="404"
          height="392"
          fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
        ></rect>
      </svg>
      <div
        class="
          mx-auto
          max-w-7xl
          w-full
          pt-16
          pb-20
          text-center
          lg:py-48 lg:text-left
          relative
          px-4
          md:px-0
        "
      >
        <div v-if="sectionData" class="px-0 lg:w-1/2 sm:px-8 xl:pr-12">
          <h1
            class="
              text-4xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-5xl
              md:text-5xl
              lg:text-5xl
              xl:text-5xl
            "
            style="line-height: 3.25rem"
          >
            <!-- <span class="block xl:inline">Planning the perfect event?</span> -->
            <span class="block xl:inline">{{ sectionData[0].topText }}</span>
            {{ " " }}
            <span class="block text-accent xl:inline">
              {{ sectionData[0].middleText }}
            </span>
            <!-- <span class="block text-accent xl:inline">
              We've got what you need!
            </span> -->
          </h1>
          <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div class="">
              <router-link to="/search" class="base-btn btn-dark">{{
                sectionData[0].linkText
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          relative
          w-full
          h-64
          sm:h-72
          md:h-96
          lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full
        "
      >
        <img
          class="absolute inset-0 w-full h-full object-cover"
          src="https://images.pexels.com/photos/2802811/pexels-photo-2802811.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt=""
        />
      </div>
    </main>
    <div class="bg-gray-50">
      <div
        class="
          mx-auto
          max-w-7xl
          w-full
          pt-16
          pb-20
          text-center
          lg:pt-12 lg:text-left
          px-6 lg:px-8
        "
      >
        <div v-if="sectionData" class="max-w-7xl mx-auto px-4 mt-10 mb-20 pb-10 sm:px-6 lg:px-8">
          <div class="text-center">
            <h2
              class="
                text-base
                font-semibold
                text-blue-600
                tracking-wide
                uppercase
              "
            >
              {{ sectionData[1].topText }}
            </h2>
            <p
              class="
                mt-1
                text-4xl
                font-extrabold
                text-gray-900
                sm:text-5xl sm:tracking-tight
                lg:text-6xl
              "
            >
              {{ sectionData[1].middleText }}
            </p>
          </div>
        </div>
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div
            class="
              mt-12
              relative
              text-base
              max-w-prose
              mx-auto
              lg:mt-0 lg:max-w-none
              h-full
            "
          >
            <div v-if="sectionData" class="relative bg-white rounded-lg shadow-lg h-full flex flex-col">
              <div class="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-12">
                <div
                  class="
                    absolute
                    top-0
                    p-5
                    inline-block
                    bg-gradient-to-r
                    from-blue-500
                    to-blue-700
                    rounded-xl
                    shadow-lg
                    transform
                    -translate-y-1/2
                    mdd:-translate-x-1/2
                    mdd:left-1/2
                  "
                >
                  <svg
                    class="h-6 w-6 text-white"
                    x-description="Heroicon name: outline/speakerphone"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                  </svg>
                </div>
                <h3
                  class="
                    text-3xl
                    font-extrabold
                    tracking-tight
                    text-gray-900
                    sm:text-4xl
                    normal-case
                    mt-10
                  "
                >
                  {{ sectionData[2].topText }}
                </h3>
                <div class="relative text-lg text-gray-700 font-medium mt-4">
                  <p class="relative">
                    {{ sectionData[2].middleText }} <br />
                  </p>
                  <!-- <p class="relative font-normal mt-4">
                    {{ sectionData[2].bottomText }} <br />
                  </p> -->
                </div>
              </div>
              <router-link
                  to="/register"
                class="
                  relative
                  block
                  bg-gradient-to-r
                  from-blue-500
                  to-blue-700
                  bg-origin-border
                  rounded-b-lg
                  not-italic
                  text-xl
                  leading-6
                  font-semibold
                  text-white
                  text-center
                  py-5
                  px-6
                  sm:py-8 mt-auto
                "
              >
                
                  {{ sectionData[2].linkText }}
                </router-link>
            </div>
          </div>
          <div
            class="
              mt-12
              relative
              text-base
              max-w-prose
              mx-auto
              lg:mt-0 lg:max-w-none
              w-full
              h-full
            "
          >
            <div v-if="sectionData" class="relative bg-white rounded-lg shadow-lg h-full">
              <div class="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-12">
                <div
                  class="
                    absolute
                    top-0
                    p-5
                    inline-block
                    indigo
                    rounded-xl
                    bg-gradient-to-r
                    from-indigo-500
                    to-indigo-700
                    shadow-lg
                    transform
                    -translate-y-1/2
                    mdd:-translate-x-1/2
                    mdd:left-1/2
                  "
                >
                  <svg
                    class="h-6 w-6 text-white"
                    x-description="Heroicon name: outline/search"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
                <h3
                  class="
                    text-3xl
                    font-extrabold
                    tracking-tight
                    text-gray-900
                    sm:text-4xl
                    normal-case
                    mt-10
                  "
                >
                  {{ sectionData[3].topText }}
                </h3>
                <div class="relative text-lg text-gray-700 font-medium mt-4">
                  <p class="relative">
                    {{ sectionData[3].middleText }} <br /> <br />
                  </p>
                  <!-- <p class="relative font-normal mt-4">
                    {{ sectionData[3].bottomText }} <br />
                    <br />
                  </p> -->
                </div>
              </div>
              <router-link
                  to="/search"
                class="
                  justify-self-end
                  relative
                  block
                  bg-gradient-to-r
                  from-indigo-500
                  to-indigo-700
                  bg-origin-border
                  rounded-b-lg
                  not-italic
                  text-xl
                  leading-6
                  font-semibold
                  text-white
                  text-center
                  py-5
                  px-6
                  sm:py-8 mt-auto
                  hover:from-indigo-500
                "
              >
                  {{ sectionData[3].linkText }}
                </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCard from "../components/ImageCard.vue";
import Container from "@/components/Container.vue";
import { ExternalLinkIcon } from "@heroicons/vue/solid";
import { OcContentService } from "../services/ocContentService";
import { CookieService } from '../services/cookieService';
export default {
  data() {
    return {
      sectionData: null,
    };
  },
  created() {
    //need to do this to check whether the nav bar needs to be updated
    if (!CookieService.getLoginCookie()) {
      this.emitter.emit("logged-in", false);
    }
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Home",
        "KrickersSectionContent"
      );
    },
  },
  components: { Container, ImageCard, ExternalLinkIcon },
};
</script>
