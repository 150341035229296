/* eslint-disable */ 
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import VueCookies from 'vue3-cookies'
import mitt from 'mitt';
import { VueReCaptcha } from 'vue-recaptcha-v3'

const emitter = mitt();

const app = createApp(App);
app.use(router);
app.use(VueCookies);
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITE_KEY });
app.config.globalProperties.emitter = emitter;
app.mount('#app')

//createApp(App).use(router).use(VueCookies).mount("#app");