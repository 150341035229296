import { createRouter, createWebHistory } from "vue-router";
import { CookieService } from "../services/cookieService";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("@/views/Services.vue"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import("@/views/Pricing.vue"),
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/Search.vue"),
  },
  {
    path: "/search/:location/:category/:subcategory/",
    name: "SearchParams",
    component: () => import("@/views/Search.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    path: "/billing",
    name: "Billing",
    component: () => import("@/views/Billing.vue"),
  },
  {
    path: "/media",
    name: "Media",
    component: () => import("@/views/Media.vue"),
  },
  {
    path: "/videos",
    name: "Videos",
    component: () => import("@/views/Videos.vue"),
  },
  {
    path: "/password",
    name: "Password",
    component: () => import("@/views/Password.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("@/views/Checkout.vue"),
  },
  {
    path: "/thankyou",
    name: "ThankYou",
    component: () => import("@/views/ThankYou.vue"),
  },
  // {
  //   path: "/profile/", //redirect back to search page if clicking a profile with no username
  //   name: "ProfileRedirect",
  //   component: () => import("@/views/Search.vue"), //() => import("@/views/Profile.vue")
  // },
  {
    path: "/profile/:username",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
  },
  // {
  //   path: "/profile/:byid/:id",
  //   name: "Profile",
  //   component: () => import("@/views/Profile.vue"),
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/forgotpassword",
    name: "Forgot Password",
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/passwordrecovery/confirm",
    name: "Reset Password",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/customer/activation",
    name: "Activate Account",
    component: () => import("@/views/Activation.vue"),
  },
  {
    path: "/services/details",
    name: "Services Details",
    component: () => import("@/views/ServiceDetails.vue"),
  },
  {
    path: "/services/investment",
    name: "Investment",
    component: () => import("@/views/services/Investment.vue"),
  },
  {
    path: "/services/employee-benefits",
    name: "Employee Benefits",
    component: () => import("@/views/services/EmployeeBenefits.vue"),
  },
  {
    path: "/services/business-planning",
    name: "Business Planning",
    component: () => import("@/views/services/BusinessPlanning.vue"),
  },
  {
    path: "/services/compensation",
    name: "Compensation",
    component: () => import("@/views/services/Compensation.vue"),
  },
  {
    path: "/services/executive-benefits",
    name: "Executive Benefits",
    component: () => import("@/views/services/ExecutiveBenefits.vue"),
  },
  {
    path: "/services/financial-planning",
    name: "Financial Planning",
    component: () => import("@/views/services/FinancialPlanning.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/Contact.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
	scrollBehavior() {
        window.scrollTo(0,0);
    },
});

router.beforeEach((to, from, next) => {
  let requiresAuth = false;
  switch (to.name) {
    case "Dashboard":
    case "Media":
    case "Videos":
    case "Password":
    case "Membership":
    case "Billing":
    requiresAuth = true;
    break;
  }
  if (requiresAuth && !CookieService.getLoginCookie()) {
    next({ name: 'Login'})
  }
  else {
    next()
  }
});

export default router;
